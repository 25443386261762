* {
	overflow-x: hidden;
}
.modes {
	position: absolute;
	top: 10vh;
	left: 2vw;
	width: 100px;
	height: 100px;
	z-index: 1000;
	/* background-color: blue; */
	display: flex;
}

.hover-effect:hover {
	text-decoration: underline 1px;
	text-underline-offset: 2px;
}

.modes div {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	margin: 10px;
	background-color: black;
	border: 2px solid white;
	cursor: pointer;
}

.modes div:first-of-type {
	background-color: white;
	border: 2px solid #5500ff;
}

.navbar {
	position: absolute;
	top: 0;
	z-index: 10;
	color: white;
}

.navbar-height {
	display: flex;
	/* background-color: #00008f4f; */
}
.navbar-height div {
	width: 50vw;
}
.hoverDiv {
	display: flex;
	justify-content: space-around;
}

.body {
	margin-top: 10vh;
	/* background: linear-gradient(135deg,#03004e,#000); */
	display: grid;
	grid-template-columns: auto;
	justify-items: center;
	align-items: center;
	height: 60vh;
}

.profile-image {
	position: relative;
	z-index: 2;
	width: 20rem;
}

.wave {
	position: absolute;
	transform: rotate(180deg);
	display: none;
}

.intro {
	/* background-color: rgb(0 11 71); */
	color: white;
	border-radius: 10px;
	position: relative;
	z-index: 2;
}
.intro-element {
	padding: 6px 0 0 20px;
	width: 25rem;
	height: 18rem;
}

.project-details {
	width: 85%;
}

.button {
	width: 130px;
	height: 50px;
	background-color: #3645b2;
	font-size: 15px;
	color: white;
	border-radius: 10px;
	border: none;
}

.skillsBackground {
	min-height: 45vh;
	/* background-color: black; */
}

.skillsBackground h1 {
	margin-top: 0;
}

.skills {
	/* background-color: black; */
	color: white;
	display: grid;
	grid-template-columns: auto;
	align-items: center;
	justify-items: center;
}

.element {
	text-align: center;
	border-radius: 10px;
	height: 180px;
	width: 600px;
	padding: 6px 0 0 20px;
	background-color: hsla(0, 0%, 100%, 0.10196078431372549);
	margin-top: 10px;
	border: #5500ffc2 2px solid !important;
}

.element img {
	margin: 10px;
	width: 60px;
}

.about {
	display: grid;
	grid-template-columns: auto auto;
	align-items: center;
	justify-items: center;
	/* background-color: black; */
	/* color: white; */
}
.about-header {
	text-align: center;
	/* color: yellow; */
	font-size: 40px;
	text-transform: uppercase;
	font-weight: 350;
}
.about-first {
	width: 35rem;
}

.links a img {
	width: 3.5rem;
}

.links {
	margin-top: 15px;
	display: grid;
	grid-template-columns: auto auto auto;
	align-items: center;
	justify-items: center;
}

.button-resume {
	width: 170px;
	height: 48px;
	border-radius: 10px;
	/* border: 4px solid #3645b2; */
	background: transparent;
	font-size: 15px;
	color: white;
}
.about-image {
	width: 25rem;
}

.Web {
	/* background-color: black; */
	color: white;
}

.grid-project {
	display: grid;
	grid-template-columns: auto auto;
	justify-items: center;
	margin: 20px;
	grid-gap: 10px;
}

.project-image {
	width: 45vw;
	border-radius: 15px;
}

.grid-translate {
	width: 40vw;
	padding: 0 32px 0 32px;
	border-radius: 15px;
}

.contact-us {
	background-color: black;
	color: white;
	text-align: center;
}
.email {
	width: 400px;
	height: 30px;
	vertical-align: middle;
	border: 0.4px dashed hsla(0, 0%, 100%, 0.3686274509803922);
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	cursor: pointer;
}

.button-form {
	cursor: pointer;
	width: 200px;
	height: 30px;
	background-color: blueviolet;
	color: white;
}

.emailmsg {
	transition: all 1s ease-in-out;
	opacity: 0;
}

.emailanim {
	opacity: 1;
}
